<template>
  <!-- 售票明细 -->
  <div ref="ticketDetails" class="ticketDetails">
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">售票时间：</div>
        <div class="col-2">
          <el-date-picker
            v-model="timeBucketValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">业务类型：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="businessType" clearable placeholder="请选择">
            <el-option
              v-for="item in businessTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">状态：</div>
        <div class="col-2 col-2-a">
          <el-select v-model="orderStatus" clearable placeholder="请选择">
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1 col-1-a">所属企业：</div>
        <div class="col-2 col-2-a">
          <companySelect
            :multiple="true"
            :clearable="true"
            :company-tree="companyList"
            :valuename="valueAddName"
            :value="valueAdd"
            @getValue="getAddGroupId"
          ></companySelect>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">退票时间：</div>
        <div class="col-2">
          <el-date-picker
            v-model="refundTicketTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>         
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun(1)"
          >查询</el-button
        >
        <el-button
          v-if="listFind('导出')"
          type="primary"
          size="small"
          @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-height="TableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
    ></Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import companySelect from "@/components/treeSelect/companySelect.vue";
import {
  getCompanyTree,
  ticketDetailsAPI,
  ticketDetailExportAPI,
} from "@/api/lib/api.js";
export default {
  components: {
    companySelect,
  },
  data () {
    return {
      pageSize: 10,
      currentPage: 1,
      total: 0,
      tableData: [],
      titleName: [
        {
          title: "业务类型",
          props: "businessType",
          SpecialJudgment: (res) => {
            return res == 1 ? "城际专车" : res == 2 ? "汽车票" : "";
          },
        },
        {
          title: "所属公司",
          props: "companyName",
        },
        {
          title: "售票时间",
          props: "createTime",
          width: "170",
        },
        {
          title: "车次",
          props: "planScheduleCode",
        },
        {
          title: "起点站",
          props: "startSiteName",
          width: "120",
        },
        {
          title: "终点站",
          props: "endSiteName",
          width: "120",
        },
        {
          title: "发车时间",
          props: "setOutTime",
          width: "170",
        },
        {
          title: "座位号",
          props: "seatNumber",
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return res == 20 ? "已支付" : res == 60 ? "已退票" : "";
          },
        },
        {
          title: "退票金额",
          props: "refundTicketPrices",
        },
        {
          title: "退票手续费",
          props: "refundTicketServicePrices",
        },
        {
          title: "退票时间",
          props: "updateTime",
          width: "170",
        },
      ],
      TableHeight: 0,
      valueAddName: null,
      valueAdd: null,
      companyList: [],
      timeBucketValue: "", //时间段
      businessType: "", //业务类型
      state: "", //类型
      orderStatus: "", //订单状态
      orderStatusList: [
        { value: 20, label: "已支付" },
        { value: 60, label: "已退票" },
      ],
      businessTypeList: [{ value: 2, label: "汽车票" }],
      temporaryForm: {},
      refundTicketTime: null,
    };
  },
  created () {
    this.getCompanyList();
    let myDate = new Date();
    this.timeBucketValue = [];
    myDate.setMonth(myDate.getMonth() - 1);
    this.timeBucketValue[0] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
    myDate.setMonth(myDate.getMonth() + 1);
    this.timeBucketValue[1] =
      myDate.getFullYear() +
      "-" +
      (myDate.getMonth() + 1) +
      "-" +
      myDate.getDate();
  },
  mounted () {
    this.queryFun();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    exportFun () {
      let t1,t2,t3,
        t4 = "";
      if (this.temporaryForm.createTimeBegin == "") {
        t1 = "";
        t2 = "";
      } else {
        t1 = new Date(this.temporaryForm.createTimeBegin).getTime();
        t2 = new Date(this.temporaryForm.createTimeEnd).getTime();
      }
      if (this.temporaryForm.refundTicketTimeBegin == "") {
        t3 = "";
        t4 = "";
      } else {
        t3 = new Date(this.temporaryForm.refundTicketTimeBegin).getTime();
        t4 = new Date(this.temporaryForm.refundTicketTimeEnd).getTime();
      }
      ticketDetailExportAPI({
        createTimeBegin: t1,
        createTimeEnd: t2,
        refundTicketTimeBegin:t3,
        refundTicketTimeEnd:t4,
        businessType: this.temporaryForm.businessType,
        companyIds: this.temporaryForm.companyIds,
        orderStatus: this.temporaryForm.orderStatus,
        currentPage: this.temporaryForm.currentPage,
        pageSize: this.temporaryForm.pageSize,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName =
          "汽车票订票明细 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");
        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击
        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 点击查询按钮
    queryFun (num) {
      if (num && num == 1) {
        this.currentPage = 1;
      }
      let time1 = "";
      let time2 = "";
      let time3 = "";
      let time4 = "";
      if (this.timeBucketValue) {
        time1 = this.GMTToStr(this.timeBucketValue[0]) + " 00:00:00";
        time2 = this.GMTToStr(this.timeBucketValue[1]) + " 23:59:59";
      }
      if(this.refundTicketTime){
        time3 = this.GMTToStr(this.refundTicketTime[0]) + " 00:00:00";
        time4 = this.GMTToStr(this.refundTicketTime[1]) + " 23:59:59";
      }
      this.renderData(time1, time2,time3,time4);
    },
    // 计算表格高度
    computeHeight () {
      const boxHeight = this.$refs["ticketDetails"].clientHeight;
      const topHeight = this.$refs["ActionBar"].clientHeight;
      this.TableHeight = boxHeight - topHeight - 90;
    },
    // 获取列表数据Fun
    renderData (time1, time2,time3,time4) {
      this.temporaryForm = {
        createTimeBegin: time1,
        createTimeEnd: time2,
        businessType: this.businessType,
        companyIds: this.companyIds,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        orderStatus: this.orderStatus,
        refundTicketTimeBegin:time3,
        refundTicketTimeEnd:time4,
      };
      ticketDetailsAPI(this.temporaryForm).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 企业回显
    getAddGroupId (v) {
      this.companyIds = v;
    },
    // 获取企业树
    getCompanyList () {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    // 当前页码改变
    onCurrentChange (page) {
      this.currentPage = page;
      this.queryFun();
    },
    // 当前条数改变
    onSizeChange (size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.queryFun();
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-range-separator {
  line-height: 26px !important;
}
.ticketDetails {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 40px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px 0 0 16px;
    padding-bottom: 0;
    display: flex;    
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: '';
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 18px);
      height: 32px;
      line-height: 32px;
      margin-right: 7px;
      margin-bottom: 16px;
      padding-right: 10px;
      .col-1-a {
        width: 70px !important;
      }
      .col-1 {
        width: 85px;
        display: inline-block;
      }
      .col-2-a {
        width: calc(100% - 70px) !important;
      }
      .col-2 {
        display: inline-block;
        width: calc(100% - 85px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
